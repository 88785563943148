<template>
  <el-dialog :visible.sync="state" width="1000px" style="margin: 0;" @close="close" height="800px" top="8vh" append-to-body>
    <div class="settin-box">
      <div class="_right">
        <div class="content_right">
          <div class="table">
            <span class="title">{{ title }}</span>
            <div class="head_line">
              <el-input type="text" v-model="form.SearchName" :placeholder="$t('StorageOutKu.GoodsName')"></el-input>
              <el-button class="button_primary" icon="el-icon-search" @click="getData()">{{ $t('BasicDept.search') }}</el-button><!--搜索-->
            </div>
            <div class="content_table">
              <div class="_filter">
              </div>
              <el-table border :data="tableData" height="calc(100vh - 455px)" v-loading="loading" stripe @selection-change="handleSelectionChange"
                :row-class-name="selectRowClassName" ref="tableList" @row-click="handleRowClick">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column :label="$t('StorageOutKu.HuoPinImg') " width="180"> <!--物资图片-->
                  <template slot-scope="scope">
                    <ImageView :url="scope.row.GoodsImage"></ImageView>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('StorageOutKu.GoodsStatus')" width="180"> <!--物资状态-->
                  <template slot-scope="scope">
                    <span :style="`color:${scope.row.GoodsStatusColor};padding: 20px 10px;border-radius: 2px;background-color:${scope.row.GoodsStatusBgColor}`">{{ scope.row.GoodsStatusName
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column prop="HuoPinCode" :label="$t('StorageOutKu.HuoPinCode')" width="180"> <!--物资编号-->
                </el-table-column>
                <el-table-column prop="HuoPinName" :label="$t('StorageOutKu.GoodsName')" width="180"> <!--物资名称-->
                </el-table-column>
                <el-table-column prop="FactStock" sortable :label="$t('StorageOutKu.FactStock')" width="180"> <!--库存数量-->
                </el-table-column>
                <!-- <el-table-column prop="CouldStock" sortable label="可以领用的库存数量 " width="180">
                </el-table-column> -->
                <el-table-column prop="BatchNumber" :label="$t('StorageOutKu.BatchNumber')" width="180"> <!--批次号-->
                </el-table-column>
                <el-table-column prop="DefaultPrice" sortable :label="$t('StorageOutKu.DefaultPrice')" width="180"> <!--默认价值-->
                </el-table-column>
                <el-table-column prop="CalcUnit" :label="$t('StorageOutKu.CalcUnit')" width="180"> <!--计数单位-->
                </el-table-column>
                <el-table-column prop="AllPrice" sortable :label="$t('StorageOutKu.AllPrice')" width="180"> <!--总值金额-->
                </el-table-column>
                <el-table-column prop="SafeStockUpperLimit" sortable :label="$t('StorageOutKu.SafeStockUpperLimit')" width="180"> <!--库存安全上限-->
                </el-table-column>
                <el-table-column prop="SafeStockLowerLimit" sortable :label="$t('StorageOutKu.SafeStockLowerLimit')" width="180"> <!--库存安全下限-->
                </el-table-column>
                <el-table-column prop="GoodsRemark" :label="$t('StorageOutKu.GoodsRemark') " width="180"> <!--物资描述-->
                </el-table-column>
                <div slot="empty" class="empty">
                  <img src="@/assets/img/empty.png" />
                  <span class="txt">{{ $t('BasicIndex.emptyData') }}</span>
                </div>
              </el-table>

            </div>
            <div class="component_footer">
              <el-pagination v-if="tableData" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="parseInt(StorageGoods.PageIndex + 1)"
                :page-sizes="[20, 50, 100]" :page-size="20" layout="total, prev, pager, next, jumper,sizes" :total="TotalCount">
              </el-pagination>
              <div class="_button">
                <el-button class="button_info" @click="close">{{ $t('AssetAllotAdd.quit')}}</el-button>
                <el-button class="button_primary" @click="addGoods()"> {{ $t('AssetAllotAdd.query') }} </el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
  
<script>
import {
  GetStorageGoodsPage
} from "@/api/user";
import mixins from "@/mixins";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  name: "SettingView",
  props: {
    type: {
      type: Boolean,
      default: false
    },
    GoodsStatus: {
      type: String,
      default() {
        return '';
      }
    },
    DataList: {
      type: Array,
      default() {
        return []
      }
    },
    warehouseid: {
      type: [String, Number],
      default: ''
    },
    seltype: {
      type: Boolean,
      default: true
    }
  },
  components: {},
  mixins: [mixins],
  data() {
    return {
      tabs: 0,
      menutab: 0,
      ID: "",
      level: "",
      drawer: false,
      filterText: "",
      icon: '',
      title: "",
      currentPage: 1,
      Title: "",
      checked: true,
      upload: false,
      dialogFormVisible: false,
      tableData: [],
      Dept: {
        DeptCode: '',
        DeptName: '',
        ParentDeptID: '',
        ParentDeptID1: '',
        ParentDeptID2: '',
        ParentDeptID3: '',
        ParentDeptID4: '',
        ParentDeptID5: '',
        ParentDeptID6: '',
        ParentDeptID7: '',
        ParentDeptID8: '',
        ParentDeptID9: '',
        ParentDeptID10: '',
        MainUserID: '',
        DeptLevel: '',
        ISCompany: '',
        DeptStatus: '',
        pid: '',
        DeptRemark: ''
      },
      TotalCount: 0,
      StorageGoods: {
        ID: '',
        GoodsCode: '',
        GoodsName: '',
        CategoryID: '',
        WarehouseID: '',
        HuoPinID: '',
        BatchNumber: '',
        GoodsRFIDEPC: '',
        GoodsImage: '',
        DefaultPrice: '',
        CalcUnit: '',
        AllPrice: '',
        FactStock: '',
        CouldStock: '',
        InKuStatus: '',
        GoodsStatus: '',
        GoodsRemark: '',
        DateOfProduction: '',
        ExpirationTime: '',
        UpdateTime: '',
        CreateTime: '',
        SafeStockUpperLimit: '',
        SafeStockLowerLimit: ''
      },
      form: {
        PageIndex: 0,
        PageSize: 20,
        StartTime: '',
        EndTime: '',
        SearchName: '',
        CategoryID: '',
        WarehouseID: '',
        InKuStatus: '',
        GoodsStatus: ''
      },
      loading: false,
      data: [],
      value: 1,
      addList: [],
      multipleSelection: []
    };
  },
  computed: {
    state: {
      get() {
        return this.type;
      },
      set() {

      }
    }
  },
  mounted() {
    if (this.warehouseid) {
      this.form.WarehouseID = this.warehouseid;
    }
    this.getData();
  },
  methods: {
    show() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < this.DataList.length; j++) {
            if (this.tableData[i].GoodsID == this.DataList[j]) {
              this.$refs.tableList.toggleRowSelection(this.tableData[i]);
            }
          }
        }
      }, 1000)
    },
    handleNodeClick(data) {
      this.Dept.ParentDeptID = data.id;
      this.level = data.level;
    },
    getData() {
      this.form.GoodsStatus = this.GoodsStatus;
      GetStorageGoodsPage(this.form).then((res) => {
        if (res.Code == 0 && res.Data) {
          this.tableData = res.Data;
          this.TotalCount = res.TotalCount;
          if (!res.Data) return
          if (res.Data.level == 1) {
            this.icon = "el-icon-office-building";
          } else {
            this.icon = "el-icon-s-home";
          }
          this.show();
        } else {
          this.tableData = [];
          this.TotalCount = 0;
        }
      });
    },
    close() {
      this.$emit('close')
    },
    addGoods() {
      this.$emit('addGoods', this.addList)
    },
    Search() { },
    selectRowClassName({ row }) {
      var color = "";
      this.multipleSelection.forEach(item => {
        if (item.GoodsID == row.GoodsID) {
          color = "warning-row";
        }
      });
      return color;
    },
    //选择整行
    handleRowClick(row) {
      if (!this.seltype) {
        this.$refs.tableList.clearSelection()
      }
      let index = this.multipleSelection.findIndex(item => {
        // 判断已选数组中是否已存在该条数据
        return item.GoodsID == row.GoodsID
      });
      if (index == -1) {
        // 如果未存在，设置已选状态，并在list中添加这条数据
        this.$refs.tableList.toggleRowSelection(row, true); //设置复选框为选中状态
      } else {
        // 如果已存在，设置未选状态，并在list中删除这条数据
        this.$refs.tableList.toggleRowSelection(row, false); //设置复选框为未选状态
      }
    },
    handleSelectionChange(val) {
      this.addList = [];
      this.addList = val;
      this.multipleSelection = JSON.parse(JSON.stringify(val));
      //获取选中点击的数据 
      if (val) {
        this.checked = false;
      } else {
        this.checked = true;
      }
    },
    handleSizeChange(val) {
      this.form.PageSize = val;
    },
    handleCurrentChange(val) {
      this.form.PageIndex = parseInt(val - 1);
      this.getData();
    },
  },
};
</script>
  
<style lang="scss" scoped>
::v-deep .el-dialog__headerbtn {
  top: 15px !important;
}
.but_add {
  display: flex;
  justify-content: space-between;
}
::v-deep .el-dialog__body {
  padding: 0px 20px !important;
}
/deep/.el-dialog {
  height: 650;
  overflow-y: auto;
}
/deep/ .el-table__empty-block {
  width: 20%;
  min-width: 121%;
  max-width: 20%;
  padding-right: 20%;
}
.companyimg {
  line-height: 150px;
}

.el-icon-plus {
  border: 1px dashed;
  // background-color: #8c939d;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.el-input--mini .el-input__inne {
  height: 39px;
}

.avatar {
  width: 135px;
  height: 128;
  display: block;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 240px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  position: relative;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;

    .content_left {
      width: 25%;
      height: calc(100vh - 700px);
      padding: 10px 10px;
      font-size: 19px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 10px 0;

          // &:hover {
          //   background-color: #409eff;
          //   color: #fff;
          //   cursor: pointer;
          // }

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .content_right {
      height: calc(100vh - 700px);
      position: absolute;
      width: 100%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        height: 80px;

        .el-input {
          width: 200px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
        box-shadow: 0 0 5px rgb(201, 199, 199);
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}
.dialog-footer {
  text-align: right;
}
._filter {
  height: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;

    &:nth-child(2) {
      margin-left: 50px;
    }
  }
}
</style>