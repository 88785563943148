<template>
  <el-dialog :visible.sync="state" :title="$t('BasicDept.SelectUser')" width="800" @close="close" custom-class="_dialog_height" append-to-body>
    <div class="settin-box">
      <div class="_right">
        <div class="content_left">
          <el-input :placeholder="$t('AdminUser.Inputorganization')" v-model="filterText">
          </el-input>
          <el-tree class="treeTable" :data="data" node-key="id" ref="tree" default-expand-all :expand-on-click-node="false" :filter-node-method="filterNode"
            @node-click="handleNodeClick">
            <span class="custom-tree-node" slot-scope="{ node,data }">
              <el-tooltip :disabled="node.label.length<PDleng(node.label)" class="item" effect="dark" :content="node.label" placement="top-start">
                <span style="font-size: 15px;" :style="DeptID == data.id ? 'color: rgb(22, 93, 255);' : ''">
                  <img src="@/assets/img/公司.png" alt="" v-if="data.level == 1 && data.children.length > 0" style="width:24px;vertical-align:middle">
                  <img src="@/assets/img/部门.png" alt="" v-if="data.level == 2 && data.children.length > 0" style="width:24px;vertical-align:middle">
                  <img src="@/assets/img/部门.png" alt="" v-if="data.children.length == 0" style="width:24px;vertical-align:middle">
                  {{ node.label | ellipsis(PDleng(node.label)) }}
                </span>
              </el-tooltip>

              <!-- <span>
                <el-dropdown trigger="click" placement="right" width="160" v-if="data.id != 0">
                  <span class="el-dropdown-link">
                    <i class="el-icon-more-outline"></i>
                  </span>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item icon="el-icon-plus" v-if="companyChecked == true" :disabled="$store.getters.Loading" @click.native="addDept(1, 1, data)">{{
                        $t('AdminUser.AddSameCDept') }}</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus" v-if="companyChecked == true" :disabled="$store.getters.Loading" @click.native="addDept(1, 2, data)">{{
                        $t('AdminUser.AddSubCDept') }}</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus" v-if="companyChecked == false" :disabled="$store.getters.Loading" @click.native="addDept(2, 1, data)">{{
                        $t('AdminUser.AddSameDept') }}</el-dropdown-item>
                    <el-dropdown-item icon="el-icon-plus" :disabled="$store.getters.Loading" @click.native="addDept(2, 2, data)">{{ $t('AdminUser.AddSubDept') }}
                    </el-dropdown-item>
                    <el-dropdown-item icon="el-icon-edit" :disabled="$store.getters.Loading" @click.native="deptUpdate(data)">{{ $t('AdminUser.update') }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span> -->
            </span>
          </el-tree>

        </div>
        <el-divider direction="vertical"></el-divider>
        <div class="content_right">
          <div class="table">
            <div class="head_line">
              <el-input type="text" :placeholder="$t('AdminUser.NameOrNumber')" v-model="form.SearchName">
                <i class="el-icon-search" slot="suffix" @click="SelectUser()" style="line-height: 2;">
                </i>
              </el-input>
              <span class="Refresh"><i class="el-icon-setting" @click="TableOk = true" style="color:#165dff;cursor: pointer;">
                  {{ $t('BasicDept.CustomizeHeader') }}</i> </span> <!--自定义表头-->
            </div>
            <div class="content_table">
              <el-table border @select-all="selectAll" @select="select" :data="tableData" v-loading="loading" height="430px" stripe @selection-change="handleSelectionChange"
                ref="tableList" :row-class-name="selectRowClassName" @row-click="handleRowClick" @header-dragend="headerDragend">
                <el-table-column type="selection" width="55"> </el-table-column>
                <el-table-column :sortable="item.sort" v-for="(item, index) in TableList" :key="index" :prop="item.propName" :label="item.labelName" :width="item.width">
                  <template slot-scope="scope">
                    <ImageView v-if="item.propName == 'UserImage'" :url="scope.row.UserImage"></ImageView>
                    <span v-else-if="item.propName == 'UserStatusName'"
                      :style="`color:${scope.row.PublicFormFontColor};padding: 20px 10px;border-radius: 2px;background-color:${scope.row.PublicFormBgColor}`">{{ scope.row.UserStatusName}}
                    </span>
                    <el-popover v-else-if="item.propName == 'UserRemark'" placement="top-start" width="200" trigger="click">
                      <span>{{ scope.row.InKuRemark }}</span>
                      <span slot="reference">{{ scope.row.InKuRemark }}</span>
                    </el-popover>
                    <span v-else>
                      {{ scope.row[item.propName] }}
                    </span>
                  </template>
                </el-table-column>
                <div slot="empty" class="empty">
                  <img src="@/assets/img/empty.png" />
                  <span class="txt">{{ $t('BasicIndex.emptyData') }}</span>
                </div>
              </el-table>
            </div>
          </div>
          <div class="component_footer">
            <el-pagination v-if="tableData" @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="parseInt(form.PageIndex + 1)"
              :page-sizes="[20, 50, 100]" :page-size="form.PageSize" layout="total, prev, pager, next, jumper,sizes" :total="TotalCount">
            </el-pagination>
            <div class="_button">
              <el-button class="button_info" @click="close">{{ $t('AssetAllotAdd.quit')}}</el-button>
              <el-button class="button_primary" @click="append()"> {{ $t('AssetAllotAdd.query') }} </el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- 增加修改页面 -->
      <el-dialog :title="DeptTitle" :visible.sync="dialogFormVisible" center lock-scroll append-to-body>
        <el-form ref="form" :rules="rules" label-position="left" label-width="100px" input-width="100px" :model="Dept">
          <el-form-item :label="$t('AdminUser.Identy')">
            <el-input :placeholder="$t('AdminUser.Identy')" v-model="Dept.DeptCode"></el-input>
          </el-form-item>
          <el-form-item :label="$t('AdminUser.IdentyNo')" prop="DeptName">
            <el-input :placeholder="$t('AdminUser.IdentyNo')" v-model="Dept.DeptName"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false" style="margin-right:65px;">{{ $t('AdminUser.quit') }}</el-button>
          <el-button type="primary" @click="updateDept()" :disabled="$store.getters.Loading"> {{
            $t('AdminUser.querydialog') }} </el-button>
        </div>
      </el-dialog>
      <!-- 新增用户 -->
      <el-dialog :title="UserTitle" :visible.sync="userChecked" top="8vh" lock-scroll append-to-body>
        <el-form ref="ruleForm" :rules="UserRules" label-position="left" label-width="125px" input-width="100px" :model="BasicUser">
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('AdminUser.UserCode')">
                <el-input :placeholder="$t('AdminUser.UserCode')" v-model="BasicUser.UserCode" style="margin-left: -5%;" maxlength="32" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('AdminUser.UserName')" prop="UserName">
                <el-input :placeholder="$t('AdminUser.UserName')" v-model="BasicUser.UserName" maxlength="32" show-word-limit>
                </el-input>
              </el-form-item>
            </el-col>

            <el-col :span="12">
              <el-form-item :label="$t('AdminUser.UserAccount')" prop="UserAccount">
                <el-input :placeholder="$t('AdminUser.UserAccount')" v-model="BasicUser.UserAccount" style="margin-left: -5%;" maxlength="32" show-word-limit></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('AdminUser.UserPassword')" prop="UserPassword">
                <el-input :placeholder="$t('AdminUser.TipPassword')" v-model="BasicUser.UserPassword" :disabled="passWordChecked == true" class="input-with-select" show-password>
                  <el-button slot="append" v-if="UserTitle == $t('AdminUser.UpdateUser')" icon="el-icon-edit" @click="passWordChecked = false, BasicUser.UserPassword = ''">
                  </el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('AdminUser.MobilePhone')">
                <el-input :placeholder="$t('AdminUser.MobilePhone')" v-model="BasicUser.MobilePhone" maxlength="13" show-word-limit style="margin-left: -5%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('AdminUser.TelePhone')">
                <el-input :placeholder="$t('AdminUser.TelePhone')" v-model="BasicUser.TelePhone" maxlength="13" show-word-limit style="margin-left: -1%;"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12"><!--所属部门-->
              <el-form-item :label="$t('BasicUserOperation.Dept')" prop="DeptName">
                <el-input :placeholder="$t('BasicUserOperation.Dept')" style="margin-left:-19px" v-model="BasicUser.DeptName" readonly class="input-with-select">
                  <el-button slot="append" icon="el-icon-thumb" @click="DeptClick(BasicUser)"></el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('AdminUser.Email')" prop="UserEmail">
                <el-input :placeholder="$t('AdminUser.Email')" v-model="BasicUser.UserEmail"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('AdminUser.UserSex')">
                <el-radio v-model="BasicUser.UserSex" :label="1">{{ $t('AdminUser.man') }}</el-radio>
                <el-radio v-model="BasicUser.UserSex" :label="2">{{ $t('AdminUser.woman') }}</el-radio>
                <el-radio v-model="BasicUser.UserSex" :label="3">{{ $t('AdminUser.unknown') }}</el-radio>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item :label="$t('AdminUser.UserRemark')">
                <el-input rows="5" type="textarea" v-model="BasicUser.UserRemark" maxlength="256" show-word-limit style="margin-left: -5%;"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item :label="$t('AdminUser.UserImage')">
                <input ref="imgfiles" :placeholder="$t('AdminUser.UserImage')" type="file" @change="selImg($event)" accept="image/gif,image/jpeg,image/jpg,image/png,image/svg"
                  style="width: 0;height: 0;" />
                <div v-show="!addUserImg" @click="upImg()" class="avatars">+</div>
                <img @click="upImg()" v-if="addUserImg" :src="addUserImg || BasicUser.UserImage" class="avatar" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="userChecked = false">{{ $t("AdminUser.quit") }}</el-button>
          <!-- 添加组织 -->
          <el-button type="primary" @click="AddUpdate()" :disabled="$store.getters.Loading">
            {{ $t("AdminUser.querydialog") }} </el-button>
        </div>
      </el-dialog>
    </div>
    <!-- 部门组件 -->
    <SelectDept v-if="DeptType" :type="DeptType" @close="DeptType = false" @addMember="addUserDept" :DataList="DeptList">
    </SelectDept>
    <DragTable v-if="TableOk" TableName="BasicUserComponents" :show="TableOk" @message="Message_">
    </DragTable>
  </el-dialog>
</template>
  
<script>
import {
  successTips,
  GetBasicDeptTree,
  GetBasicUserPage,
  updateTime,
  AddBasicDept,
  UpdateBasicDept,
  SetBasicUserColumnWidth,
  GetBasicDept,
  DeleteBasicDept,
  AddBasicUser,
  GetBasicUser,
  UpdateBasicUser,
  upLoadFile,
  LoadBasicUserColumn,
  GetBasicDeptOnlyDeptTree
} from "@/api/user";
import mixins from "@/mixins";
export default {
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    }
  },
  name: "SettingView",
  components: {},
  mixins: [mixins],
  computed: {
    state: {
      get() {
        return this.type;
      },
      set() {

      }
    }
  },
  props: {
    type: {
      type: Boolean,
      default: () => {
        return false
      }
    },
    DataList: {
      type: Array,
      default() {
        return []
      }
    },
    seltype: {
      type: Boolean,
      default: () => {
        return true
      }
    }
  },
  data() {
    var checkEmail = (rule, value, cb) => {
      //验证邮箱的正则表达式
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (regEmail.test(value) || value == '') {
        //合法的邮箱
        return cb();
      } else if (value != '') {
        //邮箱不合法
        cb(new Error(this.$t('AdminUser.TipEmail')));
      }
    }
    return {
      // state:'', 
      TotalCount: 0,
      pageNumber: 0,
      UserRoomShow: false,
      addUserImg: '',
      tabs: 0,
      menutab: 0,
      ID: "",
      level: "",
      userState: '',
      userText: this.$t('AdminUser.DisOrEn'),
      filterText: "",
      drawer: false,
      icon: '',
      UserTitle: '',
      companyChecked: false,
      dialogFormVisible: false,
      userChecked: false,
      checked: true,
      passWordChecked: false,
      // list: ["组织", "员工组"],
      list: [this.$t('AdminUser.organization')],
      title: this.$t('AdminUser.AllUser'),
      currentPage: 1,
      value: '',
      DeptTitle: "",
      DeptID: '',
      tableData: [],
      TableList: [],
      DeptList: [],
      Dept: {
        DeptCode: '',
        DeptName: '',
        ParentDeptID: '',
        ParentDeptID1: '',
        ParentDeptID2: '',
        ParentDeptID3: '',
        ParentDeptID4: '',
        ParentDeptID5: '',
        ParentDeptID6: '',
        ParentDeptID7: '',
        ParentDeptID8: '',
        ParentDeptID9: '',
        ParentDeptID10: '',
        MainUserID: '',
        DeptLevel: '',
        ISCompany: '',
        DeptStatus: '',
        pid: '',
        DeptRemark: ''
      },
      BasicUser: {
        SearchName: '',
        UserCode: '',
        UserName: '',
        UserSex: '',
        UserAccount: '',
        UserPassword: '',
        UserFace: '',
        UserUHF: '',
        UserEPC: '',
        UserImage: '',
        MobilePhone: '',
        TelePhone: '',
        UserEmail: '',
        DeptID: '',
        ManageID: '',
        UserType: '',
        UserStatus: '',
        UserRemark: '',
        UpdateTime: '',
        CreateTime: ''
      },
      form: {
        PageIndex: 0,
        PageSize: 20,
        SearchName: '',
        UserCode: '',
        UserName: '',
        UserSex: 0,
        UserAccount: '',
        UserUHF: '',
        UserEPC: '',
        MobilePhone: '',
        TelePhone: '',
        UserEmail: '',
        DeptID: 0,
        UserType: '',
        UserStatus: '',
        SortType: '',
        SortTitle: '',
        UserSexName: '',
        UserTypeName: '',
        UserStatusName: '',
      },
      BasicUserList: [],
      loading: false,
      tabslist: [
        {
          title: this.$t('AdminUser.AllUser'),
          icon: "el-icon-user",
        },
        {
          title: this.$t('AdminUser.offUser'),
          icon: "el-icon-user",
        },
      ],
      rules: {
        DeptCode: [
          { required: true, message: this.$t('AdminUser.TipDeptCode'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('AdminUser.DeptCodeLen'), trigger: 'blur' }
        ],
        DeptName: [
          { required: true, message: this.$t('AdminUser.TipDeptName'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('AdminUser.DeptCodeLen'), trigger: 'blur' }
        ]
      },
      UserRules: {
        UserCode: [
          { required: true, message: this.$t('AdminUser.TipUserCode'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('AdminUser.DeptCodeLen'), trigger: 'blur' }
        ],
        UserName: [
          { required: true, message: this.$t('AdminUser.TipUserName'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('AdminUser.DeptCodeLen'), trigger: 'blur' }
        ],
        UserAccount: [
          { required: true, message: this.$t('AdminUser.TipUserAccount'), trigger: 'blur' },
          { min: 1, max: 32, message: this.$t('AdminUser.DeptCodeLen'), trigger: 'blur' }
        ],
        UserEmail: [
          { validator: checkEmail }
        ],
        CDeptID: [
          { required: true, message: this.$t('AdminUser.TipCDeptID'), trigger: 'change' }
        ],
        DeptName: [
          { required: true, message: this.$t('AdminUser.TipDeptID'), trigger: 'change' }
        ],
        UserPassword: [
          { required: true, message: this.$t('AdminUser.TipUserPassword'), trigger: 'blur' },
          { min: 6, max: 32, message: this.$t('AdminUser.UserPasswordLen'), trigger: 'blur' }
        ]
      },
      data: [],
      // type: false,
      updatetype: false,
      DeptType: false,
      TableOk: false,
      oldImg: '',
      imageNum: 0,
      UserID: '',
      multipleSelection: [],
      deptObj: {},
      upfile: null,
      CDeptID: [],
      DeptIDList: []
    };
  },
  filters: {
    ellipsis(value, len) {
      console.log(len);
      if (!value) return ''
      if (value.length > len) {
        return value.slice(0, len) + '...'
      }
      return value
    },
  },

  mounted() {
    this.state = this.type;
    this.getData();
    this.SelectUser();
    this.DeptShow();
    this.getTableColumn();
  },
  methods: {
    select(selection) {
      if (selection.length > 1 && this.seltype == true) {
        let del_row = selection.shift()
        this.$refs.tableList.toggleRowSelection(del_row, false)
      }
    },
    selectAll(selection) {
      if (selection.length > 1 && this.seltype == true) {
        selection.length = 1
      }
    },
    // 表单加载
    getTableColumn() {
      LoadBasicUserColumn({ TableKey: 'BasicUserComponents' }).then(res => {
        if (res.Code == 0) {
          this.TableList = res.Data;
        }
      })
    },
    //刷新
    Message_() {
      this.getTableColumn();
      this.SelectUser();
      this.TableOk = false;
    },
    // 表头拖动事件
    headerDragend(newWidth, data, e) {
      data
      if (e.property != null) {
        SetBasicUserColumnWidth({ TableKey: 'BasicUserComponents', ColumnKey: e.property, Width: newWidth }).then(res => {
          if (res.Code == 0) {
            res
          }
        })
      }
    },
    PDleng(data) {
      if (/[\u4e00-\u9fa5]/g.test(data) == true) {
        return 8;
      } else {
        return 20;
      }
    },
    //部门组件事件
    addUserDept(e) {
      this.BasicUser.DeptID = e.DeptID;
      this.BasicUser.DeptName = e.DeptName;
      this.DeptType = false;
    },
    //部门点击事件
    DeptClick(data) {
      this.DeptList = [];
      if (data) {
        this.DeptType = true;
        this.DeptList.push(data.DeptID);
      }
    },
    show() {
      this.loading = true;
      setTimeout(() => {
        for (let i = 0; i < this.tableData.length; i++) {
          for (let j = 0; j < this.DataList.length; j++) {
            if (this.tableData[i].UserID == this.DataList[j]) {
              this.$refs.tableList.toggleRowSelection(this.tableData[i]);
            }
          }
        }
        this.loading = false;
      }, 1000)
    },
    //点击获取节点数据
    handleNodeClick(data) {
      if (data.level == 1) {
        this.companyChecked = true;
      } else {
        this.companyChecked = false;
      }
      this.Dept.pid = data.pid;
      this.Dept.ParentDeptID = data.id;
      this.level = data.level;
      this.DeptID = data.id;
      this.SelectUser();
    },
    DeptShow() {
      //领用公司渲染
      GetBasicDeptTree({ OnlyCompany: 1 }).then((res) => {
        if (res.Code == 0) {
          this.CDeptID = res.Data;
        }
      });
    },
    //下拉框搜索
    selectClick(e) {
      this.BasicUser.DeptName = "";
      this.BasicUser.CDeptID = e.id;
      //使用部门渲染
      GetBasicDeptOnlyDeptTree({ DeptID: e.id }).then((res) => {
        if (res.TotalCount == 0) {
          this.DeptIDList = [];
          this.BasicUser.DeptID = '';
        }
        if (res.Code == 0) {
          this.DeptIDList = res.Data;
        }
      });
    },
    clear() {
      this.BasicUser.DeptName = '';
      this.BasicUser.DeptID = '';
      this.BasicUser.CDeptName = '';
      this.BasicUser.CDeptID = '';
      this.DeptIDList = [];
    },
    clearDept() {
      this.BasicUser.DeptName = '';
      this.BasicUser.DeptID = '';
    },
    selectDept(e) {
      this.$nextTick(() => {
        this.BasicUser.DeptID = e.id;
        this.BasicUser.DeptName = e.label;
      })
    },
    //获取组织架构树状图
    getData() {
      GetBasicDeptTree().then((res) => {
        if (res.Code == 0) {
          if (res.Data != null) {
            let obj = { pid: 0, id: 0, label: this.$t('AdminUser.AllOrg'), level: 1, status: 1, children: [] };
            res.Data.unshift(obj);
            this.data = res.Data;
            if (res.Data.level == 1) {
              if (res.Data.children == []) {
                this.icon = "el-icon-school";
              } else {
                this.icon = "el-icon-office-building";
              }
            }
          }
        }
      });
    },
    SelectUser() {
      this.form.DeptID = this.DeptID;
      if (this.form.DeptID == "" || this.form.DeptID == null) {
        this.form.DeptID = 0;
      }
      GetBasicUserPage(this.form).then((res) => {
        if (res.Code == 0 && res.Data) {
          this.tableData = res.Data;
          this.TotalCount = res.TotalCount;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].CreateTime = updateTime(this.tableData[i].CreateTime);
          }
          this.show();
        } else {
          this.tableData = [];
          this.TotalCount = 0;
        }
      });
    },
    SelectPlat() {
      //高级查询 
      this.BasicUser.PageSize = 20;
      this.BasicUser.PageIndex = 0;
      this.BasicUser.SearchName = "";
      GetBasicUserPage(this.BasicUser).then((res) => {
        if (res.Code == 0) {
          this.tableData = res.Data;
          for (let i = 0; i < this.tableData.length; i++) {
            this.tableData[i].CreateTime = updateTime(this.tableData[i].CreateTime);
          }
        }
      });
    },
    handleSizeChange(val) {
      this.form.PageSize = val; //每页数量
      this.SelectUser();
    },
    handleCurrentChange(val) {
      this.form.PageIndex = parseInt(val - 1);//第几页
      this.SelectUser();

    },
    filterNode(value, data) {
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    close() {
      this.$emit('close')
    },
    append() {
      if (!this.seltype) {
        this.$emit('addMembers', this.BasicUserList);
      } else {
        this.$emit('addMember', this.BasicUser);
      }
    },
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex((d) => d.id === data.id);
      children.splice(index, 1);
    },
    resetting() {
      //重置
      this.clearImg();
      for (let i in this.BasicUser) {
        this.BasicUser[i] = '';
      }
    },
    Search() { },
    selectRowClassName({ row }) {
      var color = "";
      this.multipleSelection.forEach(item => {
        if (item.UserID == row.UserID) {
          color = "warning-row";
        }
      });
      return color;
    },
    //选择整行
    handleRowClick(row) {
      this.BasicUser = row;
      if (this.seltype) {
        this.$refs.tableList.clearSelection()
      }
      let index = this.multipleSelection.findIndex(item => {
        // 判断已选数组中是否已存在该条数据
        return item.UserID == row.UserID
      });
      if (index == -1) {
        // 如果未存在，设置已选状态，并在list中添加这条数据
        this.$refs.tableList.toggleRowSelection(row, true); //设置复选框为选中状态
      } else {
        // 如果已存在，设置未选状态，并在list中删除这条数据
        this.$refs.tableList.toggleRowSelection(row, false); //设置复选框为未选状态
      }
    },
    handleSelectionChange(val) {
      // this.BasicUser = val;
      this.multipleSelection = JSON.parse(JSON.stringify(val))
      // 获取选中点击的数据 
      if (this.seltype) {
        if (val.length > 0) {
          this.BasicUserList = val;
          this.BasicUser = val[0];
          this.checked = false;
          this.UserID = val[0].UserID;
        } else {
          this.checked = true;
        }
      } else {
        if (val) {
          this.BasicUserList = val;
          this.checked = false;
        } else {
          this.checked = true;
        }
      }
    },
    //deptName 1：企业   2：部门
    //level  1：同级  2：下级 
    addDept(ISCompany, level, value) {
      this.deptObj = { ISCompany, level, value }
      if (ISCompany == 1 && level == 1) {
        this.DeptTitle = this.$t('AdminUser.AddSameCDept');
      } else if (ISCompany == 1 && level == 2) {
        this.DeptTitle = this.$t('AdminUser.AddSubCDept');
      } else if (ISCompany == 2 && level == 1) {
        this.DeptTitle = this.$t('AdminUser.AddSameDept');
      } else if (ISCompany == 2 && level == 2) {
        this.DeptTitle = this.$t('AdminUser.AddSubDept');
      } else {
        this.DeptTitle = this.$t('AdminUser.UpdateOrg');
      }
      this.dialogFormVisible = true;
    },
    updateDept() {
      let state = false;
      this.$refs.form.validate((valid) => {
        if (valid) {
          state = true;
        }
      })
      if (!state) return
      let { DeptCode, DeptName } = this.Dept;
      let { ISCompany, value, level } = this.deptObj;
      let { id, pid } = value;
      if (level != 3) {
        let ParentDeptID = level == 1 ? pid : id;
        AddBasicDept({ ISCompany, ParentDeptID, DeptCode, DeptName }).then(res => {
          if (res.Code == 0) {
            successTips(res.Message);
            this.getData();
            this.dialogFormVisible = false;
          }
        })
      } else {
        UpdateBasicDept({ DeptID: id, DeptName, DeptCode }).then(res => {
          if (res.Code == 0) {
            successTips(res.Message);
            this.dialogFormVisible = false;
            this.getData();
          }
        }
        );
      }
    },
    deptUpdate(data) {
      this.deptObj.value = data;
      this.deptObj.level = 3;
      GetBasicDept({ DeptID: data.id }).then((res) => {
        if (res.Code == 0) {
          this.Dept.DeptCode = res.Data.DeptCode;
          this.Dept.DeptName = res.Data.DeptName;
          this.Dept.ISCompany = res.Data.ISCompany == 1 ? this.$t('AdminUser.Company') : this.$t('AdminUser.Dept');
          this.dialogFormVisible = true;
        }
      });
    },
    deleteDept(data) {
      this.$confirm(this.$t('AdminUser.tips'), this.$t('AdminUser.TipTitle'), {
        confirmButtonText: this.$t('AdminUser.querydialog'),
        cancelButtonText: this.$t('AdminUser.quit'),
        type: 'warning'
      }).then(() => {
        DeleteBasicDept({ DeptID: data.id }).then((res) => {
          if (res.Code == 0) {
            successTips(res.Message);
            this.SelectData();
            this.getData();
          }
        });
      })
    },
    //普通查询
    SelectData() {
      for (let i in this.form) {
        this.form[i] = '';
      }
      this.form.PageIndex = 0;
      this.form.PageSize = 20;
      this.SelectUser();
    },
    addUser() {
      for (let i in this.BasicUser) {
        this.BasicUser[i] = '';
      }
      this.BasicUser.UserSex = 3;
      this.addUserImg = "";
      this.passWordChecked = false;
      this.userChecked = true;
    },
    upImg() {
      this.$refs.imgfiles.click();
    },
    selImg(e) {
      if (e.target.files.length == 0) {
        return
      }
      let file = e.target.files[0];
      if (this.LimitaTionImg(file)) {
        return;
      }
      this.upfile = file;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = e => {
        this.addUserImg = e.target.result;
      }
    },
    async AddUpdate() {
      let state = false;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          state = true;
        }
      })
      if (!state) return
      this.loading = true;
      if (this.upfile) {
        let formData = new FormData();
        formData.append('file', this.upfile);
        let ret = await upLoadFile(formData);
        if (ret.Code == 0) {
          this.BasicUser.UserImage = ret.Data;
        }
      }
      //添加修改用户
      if (this.UserTitle == this.$t('AdminUser.AddUser')) {
        AddBasicUser(this.BasicUser).then((res) => {
          if (res.Code == 0) {
            successTips(this.$t('AdminUser.AddSuccess'));
            this.SelectData();
            this.oldImg = '';
            this.userChecked = false;
            this.BasicUser.UserImage = '';
            this.imageNum = 0;
          }
        });
      } else if (this.UserTitle == this.$t('AdminUser.UpdateUser')) {
        UpdateBasicUser(this.BasicUser).then((res) => {
          if (res.Code == 0) {
            this.ok = true;
            successTips(this.$t('AdminUser.UpdateSuccess'));
            this.BasicUser.UserImage = '';
            this.imageNum = 0;
            this.updatetype = false;
            this.userChecked = false;
            if (this.oldImg) {
              this.clearImg(this.oldImg);
            }
            this.oldImg = '';
            this.SelectData();
          }
        });
        this.ok = false;
      }
      this.dialogFormVisible = false;
      this.loading = false;
      this.upfile = null;

    },
    //用户修改页面
    updateUser() {
      this.DeptShow();
      this.addUserImg = '';
      GetBasicUser({ UserID: this.UserID }).then((res) => {
        if (res.Code == 0 && res.Data) {
          this.BasicUser = res.Data;
          this.addUserImg = res.Data.UserImage;
          //使用部门渲染 
          GetBasicDeptOnlyDeptTree({ DeptID: res.Data.CDeptID }).then((res) => {
            if (res.Code == 0) {
              this.DeptIDList = res.Data;
            }
          });
        }
      });
      this.updatetype = true;
      this.userChecked = true;
      this.passWordChecked = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.but_add {
  display: flex;
  justify-content: space-between;
}
.el-divider.el-divider--vertical {
  height: 500px;
  margin-top: 25px;
  margin-left: 5px;
}

.el-dialog__title {
  font-size: 16px;
}

.dot {
  display: inline-block;
  width: 5px;
  height: 5px;
  background-color: #c00;
  border-radius: 50%;
  /* 小圆点 */
  margin-right: 12px;
  /* 和文本之间的距离 */
  vertical-align: middle;
  /* 垂直居中 */
}

::v-deep .el-tree-node__content {
  margin-top: 10px;
}

::v-deep ._dialog_height {
  width: 1000px !important;
  height: 650px !important;
}
._footer {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

::v-deep .el-form-item > .el-form-item__label::before {
  content: "" !important;
  color: #000 !important;
}

::v-deep .el-form-item__label::after {
  content: "*";
  color: #ffffff;
  margin-left: 4px;
}

::v-deep .is-required .el-form-item__label::after {
  content: "*";
  color: #ff0000;
  margin-left: 4px;
}

.treeTable {
  overflow: auto;
  max-height: 455px;
}

//头像
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.el-icon-plus {
  border: 1px dashed;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

._filter {
  height: 30px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  span {
    margin-right: 20px;
    color: #1a73e8;
    font-size: 14px;
    cursor: pointer;

    .el-icon-refresh {
      margin-left: 10px;
      font-size: 16px;
    }
  }
}

._filter_btn {
  width: 100%;
  text-align: center;
  position: absolute;
  bottom: 100px;

  .el-button {
    width: 100px;
  }
}

.avatar-uploader-icon {
  font-size: 15px;
  color: #8c939d;
  width: 135px;
  height: 128;
  line-height: 120px;
  text-align: center;
}

.avatars {
  width: 135px;
  height: 128px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  line-height: 1;
  background-color: #eee;
  border: 1px solid #ccc;
  cursor: pointer;
  margin-top: -42px;

  &:hover {
    background-color: #fffefe;
  }
}

.avatar {
  width: 135px;
  height: 128px;
  display: block;
  margin-top: -38px;
}

//
.ul_but li {
  margin-bottom: 20px;
}

.ul_but:hover {
  cursor: pointer;
}

.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}

.settin-box {
  width: 100%;
  height: calc(100vh - 600px);
  display: flex;
  background-color: #fff;
  border-radius: 5px;
  margin-top: -20px;

  ul {
    padding: 0;
    margin: 0;
  }

  ._right {
    display: flex;
    flex: 1;
    position: relative;
    margin-top: -20px;

    .content_left {
      width: 30%;
      height: 604px;
      padding: 30px 20px;
      font-size: 19px;
      margin-left: -18px;

      .tabs {
        display: flex;
        height: 50px;

        .tabs_item {
          display: block;
          padding: 10px;
          line-height: 30px;

          &:hover {
            color: #409eff;
            cursor: pointer;
          }
        }
      }

      .content {
        padding: 20px 10px;

        span {
          font-size: 14px;
        }

        .title_box {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;

          .el-icon-plus {
            color: #409eff;
            cursor: pointer;
          }
        }

        .title {
          font-size: 14px;
          font-weight: 550;
          display: block;
          margin-bottom: 20px;
        }

        .cy li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          &:hover {
            background-color: #409eff;
            color: #fff;
            cursor: pointer;
          }

          span {
            margin-left: 10px;
          }
        }

        ul li {
          font-size: 14px;
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 0 0 10px;
          border-radius: 20px;
          margin: 5px 0;

          span {
            margin-left: 10px;
          }
        }
      }
    }

    .companyimg {
      line-height: 150px;
    }

    .content_right {
      height: calc(100vh - 700px);
      position: absolute;
      width: 70%;
      right: 0;
      padding: 10px;

      .head_line {
        display: flex;
        align-items: center;
        justify-content: space-between;

        height: 50px;
        .el-input {
          width: 250px !important;
        }

        .el-button {
          margin-left: 20px;
        }
      }

      .content_table {
        width: 100%;
        overflow: hidden;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    ._left {
      display: none;
    }
  }
}

.check {
  color: #409eff;
  border-bottom: 2px solid #409eff;
}

.listindex {
  color: #fff;
  background-color: #409eff;
}
</style>